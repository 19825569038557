const LogInTile = () => {
    return (
        <div className="log-in-tile">
            <h2>Welcome to ABL!</h2>
            <p>If you have been approved for an account, and already have a log in, you may now Free Your Time!</p>
            <a href="https://api.rentablapp.com:8443/login"><button className="log-in-button">Free my Time!</button></a>
        </div>
    );
}
 
export default LogInTile;