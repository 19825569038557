const WaitlistTile = () => {
    return (
        <div className="log-in-tile">
            <h2>Don't have an account?</h2>
            <p>We are currently accepting applications to enroll in the app. You can apply for an account at the after following the button below. One of our experts will be in touch to determine the next steps.</p>
            <a href="https://rentablapp.com:"><button className="log-in-button">Make my case!</button></a>
        </div>
    );
}
 
export default WaitlistTile;