import './homepage.css';
import LogInTile from './LogInTile';
import WaitlistTile from './WaitlistTile';

//<h2>Coming soon to landlords like you...</h2>

const Homepage = () => {
    return (
        <div className="homepage">
            <h1>The Future of Property Management</h1>
            <LogInTile />
            <WaitlistTile />
        </div>
    );
}
 
export default Homepage;