import './about.css';

const About = () => {
    return (
        <div className="about_page">
            <h1>About ABL</h1>
            <div className="about_body">
                <p>ABL is the product of a real estate investor fed up with the exorbitant fees property managers charge. 12% or more to start, and more hidden fees than your phone bill. Not only does it cut into the bottom line, but it severely limits the number of properties that are worthy investments. When real estate prices began to skyrocket during the COVID pandemic, those opportunities shrank even further. As rental rates started to climb, so did the monthly cut of the property managers. What did they, really, even do for that money? </p>
                <br />
                <p>Sure we’ve all heard the names that get tossed around BiggerPockets and reddit, but the current alternatives to a property manager and DIY just didn’t make sense. Why does an investor have sign away their cash flow for a property manager, or sacrifice family time to cut it? </p>
                <br />
                <br />
                <p> The birth of the future – </p>
                <br />
                <p>ABL is born. Through the rentABL module, ABL offers a high visibility product for investors to manage and track their portfolios. ABL’s customers are flocking to the app to drive cash flow, develop their exit strategies, and free their time. With ABL real estate investors can <b>DO MORE</b>!</p>
                <br />
                <p>ABL is here to make your lives easier. Join us in the journey!</p>
            </div>
            {/* <br />
            <br />
            <br />
            <img src="" alt="Andy" className="photos_us" />
            <img src="" alt="Kevin" className="photos_us" /> */}

        </div>
    );
}
 
export default About;